<template>
  <div>
    <v-app-bar app color="#1a265a" dark elevation="0" height="75">
      <v-app-bar-nav-icon @click.stop="changeDrawe"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-img
          v-if="visibleTitle"
          alt="Pedbem ADMIN"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="@/assets/gerentor_green.png"
          width="130"
        />
        <!--
        <h1 class="overline text-h6" v-if="visibleTitle">Gerentor</h1>-->
      </div>
      <v-spacer></v-spacer>

      <v-autocomplete
        rounded
        v-model="searchRoute"
        class="search-input"
        :items="menuFilter"
        item-text="title"
        item-value="link"
        flat
        hide-no-data
        hide-details
        label="Menu"
        solo-inverted
        append-icon="mdi-magnify"
        @change="searchTo()"
        height="20"
      >
      </v-autocomplete>
      <v-spacer></v-spacer>
      <v-btn text @click="logout">
        <v-icon size="22px">mdi-account-arrow-right-outline</v-icon>
      </v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon size="22px">mdi-cog-outline</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item width="8rem" text style="text-decoration: none">
            <v-switch
              @change="setDark"
              v-model="user.darkTheme"
              label="Modo Escuro"
            ></v-switch>
          </v-list-item>

          <v-list-item width="8rem" text style="text-decoration: none">
            <v-switch
              @change="setMenu"
              v-model="user.visibleMenu"
              label="Menu Visível"
            ></v-switch>
          </v-list-item>
          <v-list-item width="8rem" text style="text-decoration: none">
            <v-btn
              block
              style="text-decoration: none"
              color="green"
              dark
              small
              @click="openChangeDialog"
              >Trocar Senha</v-btn
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-dialog width="400" v-model="changePasswordDialog">
      <v-container v-if="!loading">
        <v-toolbar flat color="#4c0954" dark>
          <v-toolbar-title class="overline">Trocar Senha</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card style="padding: 16px">
          <v-card height="80px" v-if="forcePass" class="d-flex justify-center">
            <p>{{ messagePass }}</p>
          </v-card>

          <v-card
            height="30px"
            v-if="loadingAction"
            class="d-flex justify-center"
          >
            <p>Estamos processando seus dados. Aguarde...</p></v-card
          >
          <v-form>
            <br />
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  v-model="pass.oldPass"
                  placeholder="Senha Atual"
                  type="password"
                  label="Senha Atual"
                  :rules="[(d) => !!d || 'Senha Atual é requerido']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-n8">
                <v-text-field
                  outlined
                  type="password"
                  v-model="pass.newPass"
                  placeholder="Nova Senha"
                  :rules="[(v) => !!v || 'Campo obrigatório']"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-card-actions class="d-flex justify-end">
              <v-row>
                <v-col cols="12">
                  <v-btn
                    :loading="loadingAction"
                    block
                    depressed
                    color="success"
                    @click="changePassword"
                    >Enviar</v-btn
                  >
                </v-col>
                <v-col cols="12">
                  <v-btn
                    outlined
                    block
                    depressed
                    color="primary"
                    @click="
                      changePasswordDialog = false;
                      pass.oldPass = '';
                      pass.newPass = '';
                    "
                    >Cancelar</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-container>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
  </div>
</template>
<script>
/*eslint-disable */
import Vue from "vue";
import http from "@/services/http";
export default Vue.extend({
  name: "Navbar",
  data: () => ({
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    loading: false,
    loadingAction: false,
    searchRoute: "",
    visibleTitle: true,
    changePasswordDialog: false,
    messagePass: "",
    forcePass: false,
    pass: {
      oldPass: "",
      newPass: "",
    },
    user: {
      visibleMenu: true,
      darkTheme: false,
      admin: false,
      group: {
        permissions: [],
      },
    },
    menuFilter: [],
    menu: [
      {
        title: "Dashboard",
        icon: "mdi-view-dashboard",
        link: "/",
        permission: "gerentor.dashboard",
      },
      {
        title: "Membros",
        icon: "mdi-card-account-details-outline",
        link: "/members",
        permission: "gerentor.person.customers.get_many",
      },
      {
        icon: "mdi-storeftont",
        title: "Aniversariantes",
        link: "/customers/birthdays",
        permission: "gerentor.person.customers.get_many",
      },
      {
        title: "Grupos/Células",
        icon: "mdi-google-maps",
        link: "/promise/cells",
        permission: "promise.cells.get_many",
      },
      {
        icon: "mdi-storeftont",
        title: "Redes",
        link: "/promise/networks",
        permission: "promise.cells.get_many",
      },
      {
        title: "Ministérios",
        icon: "mdi-playlist-music",
        link: "/promise/ministry",
        permission: "promise.cells.get_many",
      },
      {
        title: "Extrato Conta",
        icon: "mdi-chart-pie",
        link: "/finance/account-reports",
        permission: "gerentor.finance.transactions.get_many",
      },
      {
        title: "Financeiro",
        icon: "mdi-finance",
        link: "/finance/movements",
        permission: "gerentor.finance.transactions.get_many",
      },
      {
        title: "Contas Financeiras",
        icon: "mdi-finance",
        link: "/finance/accounts",
        permission: "gerentor.finance.accounts.get_many",
      },
      {
        icon: "mdi-storeftont",
        title: "Classificações Financeiras",
        link: "/finance/categories",
        permission: "gerentor.finance.categories.get_many",
      },
      {
        icon: "mdi-storeftont",
        title: "Métodos de Pagamento",
        link: "/finance/payment-methods",
        permission: "gerentor.finance.payment_methods.get_many",
      },
      {
        title: "Contas a Pagar",
        icon: "mdi-currency-usd-off",
        link: "/finance/report/account-payables",
        permission: "gerentor.finance.transactions.get_many",
      },
      {
        title: "Projeção Entradas",
        icon: "mdi-currency-usd",
        link: "/finance/report/account-receivables",
        permission: "gerentor.finance.transactions.get_many",
      },
      {
        title: "Funcionários",
        icon: "mdi-account-group",
        link: "/base/employees",
        permission: "gerentor.person.employees.get_many",
      },
      {
        icon: "mdi-storeftont",
        title: "Pastores",
        link: "/shepherds",
        permission: "gerentor.person.employees.get_many",
      },
      {
        title: "Fornecedores",
        icon: "mdi-office-building-marker",
        link: "/base/suppliers",
        permission: "gerentor.suppliers.get_many",
      },
      {
        title: "Eventos",
        icon: "mdi-school",
        link: "/events",
        permission: "promise.events.get_many",
      },
      {
        title: "Patrimonio",
        icon: "mdi-chart-areaspline",
        link: "/patrimony",
        permission: "gerentor.products.get_many",
      },
      {
        icon: "mdi-storeftont",
        title: "Classificações de Patrimonio",
        link: "/patrimony/categories",
        permission: "gerentor.product.categories.get_many",
      },
      {
        icon: "mdi-storeftont",
        title: "Movimento Patrimonial",
        link: "/patrimony/transactions",
        permission: "gerentor.stock_transactions.get_many",
      },
    ],
  }),
  methods: {
    changePassword() {
      this.loadingAction = true;
      http.put(`/auth/me/change-password`, this.pass).then(
        (res) => {
          localStorage.setItem("token", res.data.access_token);
          const user = JSON.stringify(res.data.user);
          localStorage.setItem("user", user);

          this.snackbar.text = "Senha alterada com sucesso!";
          this.snackbar.color = "green";
          this.snackbar.opened = true;
          this.loadingAction = false;
          this.changePasswordDialog = false;
          this.forcePass = false;
        },
        (err) => {
          const { response } = err;
          this.snackbar.text =
            "Falha ao atualizar a senha: " + response.data.message;
          this.snackbar.color = "red";
          this.snackbar.opened = true;
          this.loadingAction = false;
        }
      );
    },
    openChangeDialog() {
      this.changePasswordDialog = true;
    },
    searchTo() {
      this.$router.push(this.searchRoute);
    },
    validPermission() {
      if (this.user.admin) {
        this.menuFilter = this.menu;
        return;
      }
      const permissions = this.user?.group?.permissions?.map((d) => d.id);
      if (!permissions.length) {
        return;
      }
      for (const item of this.menu) {
        if (
          permissions.includes(item.permission) ||
          item.permission === "all" ||
          (this.user && this.user.admin)
        ) {
          this.menuFilter.push(item);
        }
      }
    },
    changeDrawe() {
      this.visibleTitle = !this.visibleTitle;
      this.$root.$emit("changeDrawe");
    },
    logout() {
      localStorage.clear();
      location.reload();
      this.$router.push("/login");
    },
    setDark(isDark) {
      const payload = {
        isDark,
      };
      http.put("users/me/preferences", payload).then((res) => {
        const { data } = res;
        localStorage.setItem("user", JSON.stringify(data));
        location.reload();
      });
    },
    setMenu(value) {
      this.user.visibleMenu = value;
      const payload = {
        visibleMenu: value,
      };
      http.put("users/me/preferences", payload).then((res) => {
        const { data } = res;
        localStorage.setItem("user", JSON.stringify(data));
        location.reload();
      });
    },
    init() {
      const us = localStorage.getItem("user");
      if (us && typeof us === "string") {
        const vs = JSON.parse(us);
        this.visibleTitle = !vs.visibleMenu;
        this.user = vs;
      }
    },
  },
  mounted() {
    this.init();
    this.validPermission();
  },
});
</script>
